import React, { Component } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import axios from 'axios'
// import mslantGlass from '../images/mag-slant-white.png'
import { Link } from 'gatsby'
import {
	Grid,
	Container,
	// Modal,
	Segment,
	Divider,
	List,
	Button,
	// Dropdown,
} from 'semantic-ui-react'
import './pricing.css.less'
import priceBg1 from '../images/city-blue-mini.png'
import docs from '../images/pricing/docs.png'
import lawyer from '../images/pricing/lawyer.png'
import virtualLibrary from '../images/pricing/virtual_library.png'
import libBookmark from '../images/pricing/library-bookmark.png'
import searchCases from '../images/pricing/searchcases.png'
import singleUser from '../images/pricing/single-user.png'
import NonLawyer from '../images/pricing/nonlawyer.png'
import mlwAssist from '../images/pricing/mlwassist-icon.png'
import summaries from '../images/pricing/summaries.png'
import context from '../images/pricing/context.jpg'
import microscope from '../images/pricing/research_microscope.png'
import ipadd from '../images/pricing/ip.png'
import multilogin from '../images/pricing/multiplelogin.png'
import unliuser from '../images/pricing/unliusers.png'
import usage from '../images/pricing/usage.png'
import { CREDITS_API_URL } from 'gatsby-env-variables'

// const organization_type = [
// 	{
// 		key: 'School',
// 		text: 'School',
// 		value: 'school',
// 	},
// 	{
// 		key: 'Law Firm',
// 		text: 'Law Firm',
// 		value: 'law firm',
// 	},
// 	{
// 		key: 'Business',
// 		text: 'Business',
// 		value: 'business',
// 	},
// 	{
// 		key: 'Government Agency',
// 		text: 'Government Agency',
// 		value: 'government agency',
// 	},
// ]

class Pricing extends Component {
	state = {
		first_name: '',
		last_name: '',
		email: '',
		organization: '',
		org_type: '',
		job_title: '',
		mobile_no: '',
		no_of_access: '',
		needs: '',
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleSelect = (event, data) => {
		this.setState({
			org_type: data.value,
		})
	}

	handleSubmit = (event) => {
		event.preventDefault()

		const contact = {
			first_name: this.state.first_name,
			last_name: this.state.last_name,
			email: this.state.email,
			organization: this.state.organization,
			org_type: this.state.org_type,
			job_title: this.state.job_title,
			mobile_no: this.state.mobile_no,
			no_of_access: this.state.no_of_access,
			needs: this.state.needs,
		}

		axios
			.post(`${CREDITS_API_URL}`, { contact })
			.then((res) => {
				alert(res.data.message)
				// console.log(res.data)
			})
			.catch((error) => {
				alert('Something went wrong')
				// console.log(error.response)
			})
	}
	render() {
		return (
			<Layout>
				<Seo title="pricing" />
				<div className="pricing-container-price">
					<div className="color-overlay"></div>
					<Container>
						<img src={priceBg1} alt="" className="bg-img1" />
						<Grid stackable columns="3">
							<Grid.Row className="pricing-content-price">
								<Grid.Column width="16">
									<h3
										style={{
											color: 'white',
											fontWeight: '500',
											lineHeight: '1em',
											textAlign: 'center',
										}}
									>
										CHOOSE YOUR PLAN
									</h3>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row stretched>
								<Grid.Column>
									<Segment.Group
										style={{
											borderRadius: '30px 30px 30px 30px',
											display: 'grid',
										}}
									>
										<Segment
											raised
											style={{
												borderRadius: '30px 30px 0 0',
												background: '#1882B5',
												color: 'white',
											}}
										>
											<h5 className="plan-title">Students</h5>
										</Segment>
										<Segment raised style={{ borderRadius: '0 0 30px 30px' }}>
											<span className="plan-subtext">Month-to-month</span>
											<h5 className="plan-price">
												₱ 3,000<sup>.00</sup>
												<sub>per month</sub>
											</h5>
											<div className="signup-link">
												<span>
													<a
														href="https://web.mylegalwhiz.com/account/sign_up?subscription_price=3000"
														style={{ color: '#1882B5' }}
													>
														SIGN UP NOW
													</a>
												</span>
											</div>

											<Divider fitted />

											<span className="plan-subtext">Annual billing</span>
											<h5 className="plan-price">
												₱ 2,500<sup>.00</sup>
												<sub>per month</sub>
											</h5>

											<div className="signup-link">
												<span>
													<sup className="plan-discount"> Save ₱6,000.00</sup>
													<br />
													<a
														href="https://web.mylegalwhiz.com/account/sign_up?subscription_price=30000"
														style={{ color: '#1882B5' }}
													>
														SIGN UP NOW
													</a>
												</span>
											</div>

											<span className="plan-description student">
												<strong>
													*Billed as one time payment of{' '}
													<span className="price"> ₱30,000.00</span>
												</strong>
											</span>
											<Divider fitted />
											<List className="plan-description student">
												<List.Item>
													<img
														src={singleUser}
														style={{ width: '2em' }}
														alt=""
													/>
													Single, non-simultaneous access
												</List.Item>
												<List.Item>
													<img src={docs} style={{ width: '2em' }} alt="" />
													Legal and Business Forms
												</List.Item>

												<List.Item>
													<img
														src={virtualLibrary}
														style={{ width: '2em' }}
														alt=""
													/>
													Knowledge research articles and definitions
												</List.Item>
												<List.Item>
													<img
														src={libBookmark}
														style={{ width: '2em' }}
														alt=""
													/>
													Library and Bookmarking System
												</List.Item>
												<List.Item>
													<img
														src={searchCases}
														style={{ width: '2em' }}
														alt=""
													/>
													Cases and References
												</List.Item>
												<List.Item>
													<img
														src={mlwAssist}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Legal Terms and Concepts
												</List.Item>
												<List.Item>
													<img
														src={summaries}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Request for Case Summaries
												</List.Item>
											</List>
											<br />
											<br />
											<br />
										</Segment>
									</Segment.Group>
								</Grid.Column>
								<Grid.Column>
									<Segment.Group
										style={{
											borderRadius: '30px 30px 30px 30px',
											display: 'grid',
										}}
									>
										<Segment
											style={{
												borderRadius: '30px 30px 0 0',
												background: '#5BBF50',
												color: 'white',
											}}
										>
											<h5 className="plan-title">Business</h5>
										</Segment>
										<Segment style={{ borderRadius: '0 0 30px 30px' }}>
											<span className="plan-subtext">Month-to-month</span>
											<h5 className="plan-price">
												₱ 5,000<sup>.00</sup>
												<sub>per month</sub>
											</h5>

											<div className="signup-link">
												<span>
													<a
														href="https://web.mylegalwhiz.com/account/sign_up?subscription_price=5000"
														style={{ color: '#5BBF50' }}
													>
														SIGN UP NOW
													</a>
												</span>
											</div>

											<Divider fitted />

											<span className="plan-subtext">Annual billing</span>
											<h5 className="plan-price">
												₱ 4,166<sup>.67</sup>
												<sub>per month</sub>
											</h5>
											<div className="signup-link">
												<span>
													<sup className="plan-discount">Save ₱10,000.00</sup>
													<br />
													<a
														href="https://web.mylegalwhiz.com/account/sign_up?subscription_price=50000"
														style={{ color: '#5BBF50' }}
													>
														SIGN UP NOW
													</a>
												</span>
											</div>
											<span className="plan-description business">
												<strong>
													*Billed as one time payment of{' '}
													<span className="price"> ₱50,000.00</span>
												</strong>
											</span>
											<Divider fitted />
											<List className="plan-description business">
												<List.Item>
													<img
														src={singleUser}
														style={{ width: '2em' }}
														alt=""
													/>
													Single, non-simultaneous access
												</List.Item>
												<List.Item>
													<img src={docs} style={{ width: '2em' }} alt="" />
													Legal and Business Forms
												</List.Item>
												<List.Item>
													<img
														src={virtualLibrary}
														style={{ width: '2em' }}
														alt=""
													/>
													Knowledge research articles and definitions
												</List.Item>
												<List.Item>
													<img
														src={mlwAssist}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Legal Terms and Concepts
												</List.Item>
												<List.Item>
													<img src={context} style={{ width: '2em' }} alt="" />
													AskLEA Drafting Assistance
												</List.Item>
												<List.Item>
													<img
														src={microscope}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Legal Research Service
												</List.Item>
												<List.Item>
													<img src={lawyer} style={{ width: '2em' }} alt="" />
													Referral to a legal expert
												</List.Item>
											</List>
											<br />
											<br />

											<br />
										</Segment>
									</Segment.Group>
								</Grid.Column>
								<Grid.Column>
									<Segment.Group
										style={{
											borderRadius: '30px 30px 30px 30px',
											display: 'grid',
										}}
									>
										<Segment
											style={{
												borderRadius: '30px 30px 0 0',
												background: '#e0ae3f',
												color: 'white',
											}}
										>
											<h5 className="plan-title">Professional</h5>
										</Segment>
										<Segment style={{ borderRadius: '0 0 30px 30px' }}>
											<span className="plan-subtext">Month-to-month</span>
											<h5 className="plan-price">
												₱ 6,000<sup>.00</sup>
												<sub>per month</sub>
											</h5>

											<div className="signup-link">
												<span>
													<a
														href="https://web.mylegalwhiz.com/account/sign_up?subscription_price=6000"
														style={{ color: '#e0ae3f' }}
													>
														SIGN UP NOW
													</a>
												</span>
											</div>

											<Divider fitted />

											<span className="plan-subtext">Annual billing</span>
											<h5 className="plan-price">
												₱ 5,000<sup>.00</sup>
												<sub> per month</sub>
											</h5>

											<div className="signup-link">
												<span>
													<sup className="plan-discount">Save ₱12,000.00</sup>
													<br />
													<a
														href="https://web.mylegalwhiz.com/account/sign_up?subscription_price=60000"
														style={{ color: '#e0ae3f' }}
													>
														SIGN UP NOW
													</a>
												</span>
											</div>

											<span className="plan-description professional">
												<strong>
													*Billed as one time payment of{' '}
													<span className="price"> ₱60,000.00</span>
												</strong>
											</span>
											<Divider fitted />
											<List className="plan-description professional">
												<List.Item>
													<img
														src={singleUser}
														style={{ width: '2em' }}
														alt=""
													/>
													Single, non-simultaneous access
												</List.Item>
												<List.Item>
													<img src={docs} style={{ width: '2em' }} alt="" />
													Legal and Business Forms
												</List.Item>
												<List.Item>
													<img
														src={virtualLibrary}
														style={{ width: '2em' }}
														alt=""
													/>
													Knowledge research articles and definitions
												</List.Item>
												<List.Item>
													<img
														src={libBookmark}
														style={{ width: '2em' }}
														alt=""
													/>
													Library and Bookmarking System
												</List.Item>
												<List.Item>
													<img
														src={searchCases}
														style={{ width: '2em' }}
														alt=""
													/>
													Cases and References
												</List.Item>
												<List.Item>
													<img
														src={mlwAssist}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Legal Terms and Concepts
												</List.Item>
												<List.Item>
													<img
														src={summaries}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Request for Case Summaries
												</List.Item>
												<List.Item>
													<img src={context} style={{ width: '2em' }} alt="" />
													AskLEA Drafting Assistance
												</List.Item>
												<List.Item>
													<img
														src={microscope}
														style={{ width: '2em' }}
														alt=""
													/>
													AskLEA Legal Research Service
												</List.Item>

												<List.Item>
													<img
														src={NonLawyer}
														style={{ width: '2em' }}
														alt=""
													/>
													Referral to business and non-lawyer subscribers
												</List.Item>
											</List>
										</Segment>
									</Segment.Group>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
					<section className="section-pricing">
						<div className="ui equal width grid">
							<div className="column">
								<a
									href="https://web.mylegalwhiz.com/account/sign_up"
									target="blank"
								>
									<Button className="btn-request">
										<span style={{ fontSize: '1.5em' }}>Subscribe</span>
									</Button>
								</a>
							</div>
							<div className="column">
								<a href="https://credits.mylegalwhiz.com/en/" target="blank">
									<Button className="btn-request">
										<span style={{ fontSize: '1.5em' }}>
											Explore with Credits
										</span>
									</Button>
								</a>
							</div>
						</div>
						{/* <Modal
							trigger={
								<Button className="btn-request">
									<span>
										<i>Request for a 7-day free trial!</i>
									</span>
								</Button>
							}
						>
							<Modal.Header>REQUEST FOR FREE 7-DAY TRIAL</Modal.Header>
							<Modal.Content>
								<span>
									We're excited to give you free access to MyLegalWhiz. Before
									we activate your 7-day free trial, we would like to know more
									about you.
								</span>
								<form className="ui form" onSubmit={this.handleSubmit}>
									<div className="inline fields">
										<div className="three wide field">
											<label htmlFor="first_name" style={{ color: 'dimgray' }}>
												First Name
											</label>
										</div>
										<div className="thirteen wide field">
											<input
												type="text"
												placeholder="First Name"
												name="first_name"
												id="first_name"
												minLength="3"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="inline fields">
										<div className="three wide field">
											<label htmlFor="last_name" style={{ color: 'dimgray' }}>
												Last Name
											</label>
										</div>
										<div className="thirteen wide field">
											<input
												type="text"
												placeholder="Last Name"
												name="last_name"
												id="last_name"
												minLength="3"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="inline fields">
										<div className="three wide field">
											<label htmlFor="email" style={{ color: 'dimgray' }}>
												Email
											</label>
										</div>
										<div className="thirteen wide field">
											<input
												type="email"
												placeholder="Email"
												name="email"
												id="email"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>

									<div className="inline fields">
										<div className="three wide field">
											<label htmlFor="org_type" style={{ color: 'dimgray' }}>
												Type of user
											</label>
										</div>
										<div className="thirteen wide field">
											<Dropdown
												placeholder="Select Organization"
												selection
												className="org_dropdown"
												name="org_type"
												id="org_type"
												options={organization_type}
												style={{ width: '100%' }}
												onChange={this.handleSelect}
											/>
										</div>
									</div>
									<div className="inline fields">
										<div className="three wide field">
											<label
												htmlFor="organization"
												style={{ color: 'dimgray' }}
											>
												Organization
											</label>
										</div>
										<div className="thirteen wide field">
											<input
												type="text"
												placeholder="Organization"
												name="organization"
												id="organization"
												minLength="3"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="inline fields">
										<div className="three wide field">
											<label htmlFor="job_title" style={{ color: 'dimgray' }}>
												Position
											</label>
										</div>
										<div className="thirteen wide field">
											<input
												type="text"
												placeholder="Job Title"
												name="job_title"
												id="job_title"
												minLength="3"
												onChange={this.handleChange}
												required
											/>
										</div>
									</div>
									<div className="field" style={{ textAlign: 'center' }}>
										<button
											className="ui inverted button primary"
											type="submit"
											name="submit"
											id="submit"
										>
											SEND REQUEST
										</button>
									</div>
								</form>
							</Modal.Content>
						</Modal> */}
					</section>
					<section className="section-pricing-1">
						<div className="ui centered grid stackable">
							<div className="four wide column pricing-1-text">
								<h5>
									For institutional or group
									<br />
									subscriptions
								</h5>
								<span>
									Contact our representative: <br />
									(02) 7966-8633
									<br />
									subscribe@mylegalwhiz.com
								</span>
								<br />
								<br />

								<Link to="/contact">
									<button className="ui inverted primary button">
										GET IN TOUCH
									</button>
								</Link>
							</div>
							<div className="two wide column pricing-1-text">
								<img src={ipadd} className="pricing-image-ip" alt="" />
								<br />
								<span>IP Restrictions</span>
							</div>
							<div className="two wide column pricing-1-text">
								<img src={multilogin} className="pricing-image" alt="" />
								<br />
								<span>Multiple Logins</span>
							</div>
							<div className="two wide column pricing-1-text">
								<img src={unliuser} className="pricing-image" alt="" />
								<br />
								<span>Unlimited Users</span>
							</div>
							<div className="two wide column pricing-1-text">
								<img src={usage} className="pricing-image" alt="" />
								<br />
								<span>Usage Statistics</span>
							</div>
						</div>
					</section>
					{/* <img src={mslantGlass} alt="" className="pricing-mslantGlass" /> */}
				</div>
			</Layout>
		)
	}
}

export default Pricing
